<template>  
    <!-- Header start-->
      <header>
        <button v-on:click="showMenu = !showMenu" v-bind:class="{ open: showMenu }" class="menu"><span></span><span></span><span></span></button>
        <div class="logo"><router-link to="/"><img src="@/assets/images/logo.png"/></router-link></div>
        <ul v-bind:class="{ open: showMenu }" class="mobile-nav">
          <li><router-link to="/">STARTSEITE</router-link></li>
          <li><router-link to="/impressum">IMPRESSUM</router-link></li>
          <li><router-link to="/datenschutz">DATENSCHUTZ</router-link></li>
        </ul>
        <ul class="header-title">
          <li>BUSSGELD-CHECK.DE</li>
        </ul>
        <!-- Progress bar-->
        <div class="progress-bar">
          <div class="progress" style="width:5%;"></div>
        </div><a class="whatsapp-header" @click="showHotlineModal(!modalHotline)" href="#mobile-phone">
          <svg>
            <use xlink:href="@/assets/images/sprite.svg#icons8-ringer-volume"></use>
          </svg></a>
        <div class="header-partners">
          <p>Bekannt aus Rundfunk und TV</p>
          <div class="partners-img"><img src="@/assets/images/welt_testimonial.png"><img src="@/assets/images/heise_testimonial.png"><img src="@/assets/images/br_testimonial.png"></div>
        </div>
      </header>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Header',
  data() {
    return {
        showMenu: false
    };
  },
  methods: {
    ...mapMutations({
        showHotlineModal: 'setModalHotline',
      }),
  },
  computed: mapState({
    step: (state) => state.step,
    user: (state) => state.user,
    modalHotline: (state) => state.modalHotline
  }),
}
</script>